import { graphql } from "gatsby";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction } from "../components/CallToAction";
import { FeaturedBlogPostsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { PageHero } from "../components/Hero";
import { BlogPostItem, Stack } from "../components/Stack";


interface Props {
  data: any
};


const BlogPage = ({
  data
}: Props ) => {
  const posts = data?.allPrismicPost?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const blogpage = data?.prismicBlogPage?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ blogpage.title.text }
        description={ blogpage.description.text }
      />

      <SectionWrapper
        background={{
          base: 'linear-gradient(#FBF7F1 0%, #FBF7F1 55%, white 55%, white 100%)',
          lg: 'linear-gradient(#FBF7F1 0%, #FBF7F1 65%, white 65%, white 100%)'
        }}
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            heading={ blogpage.hero_heading.text }
            description={ blogpage.hero_description.html }
          >
            <FeaturedBlogPostsCarousel
              posts={ posts }
            />
          </PageHero>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0 65px"
      >
        <SectionContainer>
          <Stack
            dividerVisible
            align="flex-start"
          >
            { posts.map(( node: any, _index: number ) => (
              <BlogPostItem
                key={ node.node.uid }
                data={ node.node.data }
                slug={ node.node.uid }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ blogpage.testimonials_heading.text }
          description={ blogpage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query BlogPageQuery {
    allPrismicPost(
      sort: {
        fields: data___post_date
        order: DESC
      }
    ) {
      edges {
        node {
          _previewable
          data {
            excerpt
            image {
              alt
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
            post_date(
              formatString: "MMMM DD, YYYY"
            )
            title {
              text
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicBlogPage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default BlogPage;